import throttle from '../util/throttle';
export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {
    const $body = $('body');
    var $scrollMenu = $('#scroll-menu');


    // FAQ section handler code
    $body.on('click', '.collapse-header', function () {
      $(this)
        .parents('.collapsible-wrapper')
        .find('.collapse')
        .removeClass('active');
      $(this).parent().addClass('active');
    });
    $('.collapsible-wrapper .collapse:first-child').addClass('active');


    // Adding class to body on scroll
    const scrollHandler = () => {
      var currentScrollPosition = $(window).scrollTop();
      currentScrollPosition
        ?
        $body.addClass('menu-compact') :
        $body.removeClass('menu-compact');
    };
    $(window).scroll(() => {
      throttle(scrollHandler, 250);
    });
    scrollHandler();


    // Iniit Calendly
    $body.on('click', '.init-calendly a, a.init-calendly', function (e) {
      e.preventDefault();
      window.Calendly.initPopupWidget({
        url: $(this).prop('href'),
      });
      return false;
    });


    // Contact Form 7
    // Animate the lables on focus
    if ($('.rezzi-form-section').length > 0) {
      var checkFocusOnField = function ($input) {
        if ($input.is(':focus')) {
          $input.parent().addClass('rezzi-input-has-focus');
        } else {
          $input.parent().removeClass('rezzi-input-has-focus');
        }
      };
      var checkValueInField = function ($input) {
        if ($input.val() != null && $input.val() != '') {
          $input.parent().addClass('rezzi-input-has-value');
        } else {
          $input.parent().removeClass('rezzi-input-has-value');
        }
      };
      var displayFileName = function($input) {
        if( typeof $input[0].files[0] != 'undefined' && $input[0].files[0] != null ) {
            var fileName = $input[0].files[0].name;
            $('.rezzi-form-section').find('.rezzi-upload-file-name').empty().text(fileName);
        }
      }
      $('body').on( 'change', '.rezzi-form-section input[type="file"]', function() {
          displayFileName($(this));
      });
      $('.rezzi-form-section').find('input[type="file"]').each(function(){
          displayFileName($(this));
      });

      $body.on('change', '.rezzi-form-section .rezzi-form-control', function () {
        checkValueInField($(this));
      });
      $body.on('focus blur', '.rezzi-form-section .rezzi-form-control', function () {
        checkFocusOnField($(this));
      });
      $('.rezzi-form-section')
        .find('.rezzi-form-control')
        .each(function () {
          checkFocusOnField($(this));
          checkValueInField($(this));
        });
    }

    // Top Search Form Related JS
    $('body').on('click', '.rsquote-top-search-form-handle', function (e) {
      e.preventDefault();
      e.stopPropagation();
      var $formWrapper = $('#rsquote-top-search-form');
      if (!$formWrapper.hasClass('open')) {
        $formWrapper.removeClass('open')
      } else {
        $formWrapper.addClass('open')
      }

    });

    $('body').on('click', '.rsquote-top-search-form-handle', function (e) {
      e.preventDefault();
      e.stopPropagation();
      $('#rsquote-top-search-form').toggleClass('open');
    });

    /**
     * Scroll Menu Related code
     */
    var stickySubMenuHandler = function () {
      var currentScrollPosition = $(window).scrollTop() + 70;
      if (currentScrollPosition > $scrollMenu.offset().top) {
        if (!$body.hasClass('stick-scroll-menu')) {
          $body.addClass('stick-scroll-menu');
        }
      } else {
        $body.removeClass('stick-scroll-menu');
      }
    }
    if ($scrollMenu.length) {
      $(window).scroll($.throttle(200, stickySubMenuHandler));
      stickySubMenuHandler();
    }

    /**
     * Smooth scrolling for anchor tags
     * */
    $body.on('click', '.smooth-sc a', function () {
      if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

        var scrollTopOffset = Math.ceil(target.offset().top - 120);
        if (($(window).width() <= 1024.98)) {
          scrollTopOffset = Math.ceil(target.offset().top - 170);
        }
        if (target.length) {
          $('html, body').animate({
            scrollTop: scrollTopOffset,
          }, 3000, function () {
            if ($scrollMenu.length) {
              stickySubMenuHandler();
            }
          });
          return false;
        }
      }
    });

    //Opening popup if coming from residential
    const urlParams = new URLSearchParams(window.location.search);
    const fromRS = urlParams.get('from');
    /*global PUM*/
    if(fromRS == 'rs' && PUM) {
      PUM.open(6362);
    }

    // Track conversion for Calendly call scheduled
    window.addEventListener('message', function(e) {
      if(e.origin === 'https://calendly.com' && e.data.event && e.data.event.indexOf('calendly.') === 0) {
        /* Example to get the name of the event */
        console.log('Event name:', e.data.event);
        if(e.data.event === 'calendly.event_scheduled') {
          console.log('Sending conversion event');
          window.gtag('event', 'conversion', {'send_to': 'AW-10884796428/BDekCIDrsLMDEIygo8Yo'});
          window.gtag('event', 'conversion', {'send_to': 'AW-10884877250/dIEOCPzr6L8DEMKXqMYo'});
          window.fbq('track', 'Lead');
        }
      }
    });

    //Tracking call button trigger
    $body.on('click', 'a[href^=tel]', function () {
      // console.log('Call button clicked');
      window.fbq('track', 'Contact');
    });
  },
};
